function wrapNullValue(value, msg="Неизвестно") {
  return !value ? msg : value;
}

function emptySearchFilters() {
    return  {
        online: false,
        terminalIds: null,
        terminalMacs: null,
        terminalIP: null,
        memoryFrom: null,
        memoryTo: null,
        template: null,
        vendor: null,
        model: null,
        distributionType: null,
        distributionBuild: null,
        displayNumber: null,
        displayResolution: null,
        transportAgent: null,
        transportAgentState: null,
      }
}


export { wrapNullValue, emptySearchFilters };
