<template>
  <div id="ground_intergration">
    <div class="ground_intergration_root_container">
      <div class="ground_intergration_root_container__item search_warning">
        <Transition name="base"
          ><label v-if="warning">{{ warning }}</label></Transition
        >
      </div>
      <div class="ground_intergration_root_container__item search_container">
        <div class="search_container__item search_input">
          <div class="search_input__item icon">
            <svg
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.50006 0C8.53765 0 11.0001 2.46256 11.0001 5.50029C11.0001 6.74868 10.5842 7.89993 9.88346 8.82304L13.7791 12.7233C14.0718 13.0164 14.0715 13.4913 13.7785 13.784C13.4854 14.0767 13.0105 14.0764 12.7178 13.7834L8.82266 9.88388C7.89959 10.5847 6.74839 11.0006 5.50006 11.0006C2.46246 11.0006 0 8.53802 0 5.50029C0 2.46256 2.46246 0 5.50006 0ZM5.50006 1.5C3.2909 1.5 1.5 3.29098 1.5 5.50029C1.5 7.70961 3.2909 9.50058 5.50006 9.50058C7.70921 9.50058 9.50011 7.70961 9.50011 5.50029C9.50011 3.29098 7.70921 1.5 5.50006 1.5Z"
                fill="#A1A3AB"
              ></path>
            </svg>
          </div>
          <div
            class="search_input_item search_text"
            :class="[inputIds ? 'typed' : '', !isValidInput ? 'invalid' : '']"
          >
            <input
              @input="onInputIds"
              @keyup.enter="isValidInput && anyIdInInput ? makeConvert() : null"
              placeholder="CLI IDs"
            />
          </div>
        </div>
        <div class="search_container__item convert_button">
          <button
            @click="
              isValidInput && anyIdInInput && anyIdInInput
                ? makeConvert()
                : null
            "
            :class="
              isValidInput && anyIdInInput ? 'blue_button' : 'gray_button'
            "
          >
            Конвертировать
          </button>
        </div>
        <div class="search_container__item seach_button">
          <button
            @click="convertDone ? makeTerminalSearch() : null"
            :class="allowSearch ? 'blue_button' : 'gray_button'"
          >
            Поиск
          </button>
        </div>
      </div>

      <div class="ground_intergration_root_container__item table_container">
        <div class="table">
          <div class="table_header">
            <div class="table_line_container">
              <div class="table_line_container__item ground_id_column">
                Cli ID
              </div>
              <div class="table_line_container__item mac_column">MAC адрес</div>
              <div class="table_line_container__item terminal_id_column">
                Терминал ID
              </div>
            </div>
          </div>
          <div v-if="convertedIds.length > 0" class="table_content">
            <div
              v-if="convertDone"
              :style="convertedIds.length < 10 ? 'overflow: unset' : ''"
            >
              <div
                class="table_line_container"
                v-for="(terminal, i) in getSortedConvert"
                :key="terminal.groundId"
                :class="!(i % 2) ? 'blue' : 'white'"
              >
                <div
                  class="table_line_container__item ground_id_column"
                  :title="wrapNullValue(terminal.groundId)"
                >
                  <label>{{ wrapNullValue(terminal.groundId) }}</label>
                </div>

                <div
                  class="table_line_container__item mac_column"
                  :class="!terminal.terminalMac.error ? '' : 'error'"
                  :title="wrapNullValue(terminal.terminalMac.value)"
                >
                  <label>{{
                    terminal.terminalMac.value || terminal.terminalMac.error
                  }}</label>
                </div>
                <div
                  class="table_line_container__item terminal_id_column"
                  :class="terminal.terminalId.error ? 'error' : ''"
                  :title="
                    terminal.terminalId.value ||
                    terminal.terminalId.error ||
                    '-'
                  "
                >
                  <label>
                    {{
                      terminal.terminalId.value ||
                      terminal.terminalId.error ||
                      "-"
                    }}
                  </label>
                </div>
              </div>
            </div>
            <div v-else class="terminals_load_container">
              <span class="loader"></span>
            </div>
          </div>
          <div v-else class="not_found_content">Не найдено</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { wrapNullValue, emptySearchFilters } from "../utils";
export default {
  name: "IntegrationGround",
  data() {
    return {
      inputIds: "",
      convertedIds: [],
      convertDone: false,
      limitIds:
        parseInt(process.env.VUE_APP_INTEGRATION_GROUND_IDS_LIMIT) || 10,
    };
  },

  methods: {
    wrapNullValue,
    onInputIds(event) {
      this.inputIds = event.target.value;
    },

    // redirect to terminals
    makeTerminalSearch() {
      if (this.validConverted.length > 0) {
        let filters = emptySearchFilters();
        filters.terminalIds = this.validConverted;
        this.$router.push({
          name: "Terminals",
          query: { filters: btoa(JSON.stringify(filters)) },
        });
      }
    },
    async makeConvert() {
      this.convertDone = false;
      this.convertedIds = [];
      try {
        await this._makeConvert();
      } catch {
        console.log("Error during make convert");
      } finally {
        this.convertDone = true;
      }
    },
    async _makeConvert() {
      for (const id of [...new Set(this.validIdInInput)]) {
        // get mac
        const [mac, mac_err] = await this.convertClIIDToMac(id);
        if (mac_err !== null) {
          this.convertedIds.push({
            groundId: id,
            terminalMac: { value: null, error: mac_err },
            terminalId: { value: null, error: null },
          });
          continue;
        }
        // get terminal id
        const [terminal_id, terminal_id_err] = await this.convertMacToId(mac);
        if (terminal_id_err !== null) {
          this.convertedIds.push({
            groundId: id,
            terminalMac: { value: mac, error: null },
            terminalId: { value: null, error: terminal_id_err },
          });
          continue;
        }

        // add suc convert
        this.convertedIds.push({
          groundId: id,
          terminalMac: { value: mac, error: null },
          terminalId: { value: terminal_id, error: null },
        });
      }
    },
    // convert cli to mac
    async convertClIIDToMac(id) {
      return await this.$http
        .post(`${this.$backEndUrl}/v1/proxy/integration`, {
          url: `/v1/getmac?cliId=${id}`,
          method: "GET",
          payload: {},
          headers: {},
        })
        .then((res) => {
          console.log(
            "Response from ground integration obtained (cli to mac)",
            res
          );
          if (res.data.statusCode === 200) {
            return [res.data.payload.mac, null];
          }
          return [null, `${res.data.payload.msg}`];
        })
        .catch((error) => {
          console.log("Error to get images", error);
          return [null, "Net Error"];
        });
    },
    // convert mac to terminal id
    async convertMacToId(mac) {
      return await this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/terminals/macs`,
          method: "POST",
          payload: { terminals: [mac] },
          headers: {},
        })
        .then((res) => {
          console.log(
            "Response from ground integration obtained (cli to mac)",
            res
          );
          if (
            res.data.statusCode === 200 &&
            res.data.payload.terminals.length > 0
          ) {
            return [res.data.payload.terminals[0].terminalId, null];
          }
          return [null, "Terminal not found"];
        })
        .catch((error) => {
          console.log("Error to get images", error);
          return [null, "Net Error"];
        });
    },
  },

  computed: {
    getSortedConvert() {
      return [...this.convertedIds].sort((a, b) => a.groundId - b.groundId);
    },
    //input warning
    warning() {
      if (this.validIdInInput.length > this.limitIds) {
        return `Превышено максимальное число ids (limit: ${this.limitIds})`;
      }
      if (!this.isValidInput) {
        return `Неправильный формат (необходимый формат: 12, 100, 13)`;
      }
      return null;
    },
    isValidInput() {
      let valid = true;
      this.inputIds
        .split(",")
        .map((x) => x.trim())
        .forEach((el) => {
          if (isNaN(Number(el)) || Number(el) < 0) {
            valid = false;
            return;
          }
        });
      return valid;
    },
    anyIdInInput() {
      return (
        this.validIdInInput.length > 0 &&
        this.validIdInInput.length <= this.limitIds
      );
    },
    validIdInInput() {
      return this.inputIds.split(",").filter((el) => {
        el = el.trim();
        if (!isNaN(Number(el)) && Number(el) > 0) {
          return el;
        }
      });
    },
    allowSearch() {
      return this.convertDone && this.validConverted.length > 0;
    },
    validConverted() {
      return this.convertedIds
        .filter((el) => {
          if (el.terminalId.value && !isNaN(Number(el.terminalId.value))) {
            return true;
          }
        })
        .map((x) => x.terminalId.value);
    },
  },
  created() {},
};
</script>

<style lang="less">
@import "../assets/styles/animations.less";
@import "../assets/styles/colors.less";
#ground_intergration {
  .ground_intergration_root_container {
    display: flex;
    flex-direction: column;
  }

  .search_warning {
    color: @invalid-color;
    height: 10px;
    font-size: 12px;
    margin-left: 5px;
    margin-bottom: 3px;
  }

  .search_container {
    display: flex;
    align-items: center;

    .search_input {
      height: 35;
      width: 400px;
    }

    .blue_button {
      margin-left: 20px;
      height: 20px;
    }
    .gray_button {
      margin-left: 20px;
      height: 20px;
      cursor: unset;
    }
  }

  .table {
    margin-top: 15px;
    margin-bottom: 20px;
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
    // display: flex;
    // flex-direction: column;
    .table_header {
      font-weight: 500;
      font-size: 15px;
      font-family: Roboto;
      font-style: normal;
      line-height: 18px;
      position: sticky;
      top: 0;
      background-color: white;
      height: 40px;

      .table_line_container {
        height: 30px;
        cursor: unset;
        .table_line_container__item {
          cursor: unset;
          border-left: 1px solid rgba(65, 80, 183, 0.3);
          padding: 10px 11px 11px;
          height: 100%;
          font-size: 15px;
        }
      }
    }
    .table_line_container {
      display: flex;
      height: 40px;
      align-items: center;

      &.white {
        background: none;
      }
      &.blue {
        background: #f1f6ff;
      }
      .table_line_container__item {
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        white-space: nowrap;
        padding: 10px 11px 11px;
        display: flex;
        align-items: center;
        overflow: hidden;
        text-overflow: ellipsis;

        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;

        label {
          max-width: 98%;
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: pointer;
        }

        &.ground_id_column {
          width: 30%;
          border-left: unset;
        }

        &.mac_column {
          width: 30%;
          &.error {
            color: @invalid-color;
          }
        }
        &.terminal_id_column {
          width: 30%;
          &.error {
            color: @invalid-color;
          }
        }
      }
    }
    .not_found_content {
      display: flex;
      justify-content: center;
      height: 200px;
      align-items: center;
    }

    .terminals_load_container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50vh;

      .loader {
        width: 30%;
        height: 2.8px;
        display: inline-block;
        position: relative;
        background: #4250b74d;
        border-radius: 15px;

        overflow: hidden;
      }
      .loader::after {
        content: "";
        width: 192px;
        height: 4.8px;
        background: #4150b7;
        position: absolute;
        box-sizing: border-box;

        animation: animloader 2s linear infinite;
      }
      @keyframes animloader {
        0% {
          left: 0;
          transform: translateX(-100%);
        }
        100% {
          left: 100%;
          transform: translateX(0%);
        }
      }
    }
  }
}
</style>
